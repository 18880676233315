import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI'; 
import { useSelector } from 'react-redux';

export const QuestionFermee = ({ type, textQuiz }) => {
  const user = useSelector((state) => state.user);

  const [question, setQuestion] = useState('');
  const [selectedChoice, setSelectedChoice] = useState('');
  const [corrections, setCorrections] = useState([]); // Pour stocker les corrections
  const [userResponses, setUserResponses] = useState([]); // Pour stocker les réponses de l'utilisateur
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalResults, setFinalResults] = useState(null); // Pour stocker les résultats finaux
  const [currentCorrectionIndex, setCurrentCorrectionIndex] = useState(0); // Index de correction actuelle
  const [totalQuestions, setTotalQuestions] = useState(10); // Dynamique, mis à jour via API
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Pour suivre la question actuelle

  // Fonction pour générer la question et éventuellement récupérer le nombre de questions
  const fetchQuestion = useCallback(async () => {
    setIsLoading(true); // Activer l'état de chargement
    try {
      const data = await CallAPI(type, null, textQuiz, user, 'quiz');
      
      if (data.isQuestion) {
        setQuestion(data.content);
        setSelectedChoice(''); // Réinitialiser le choix sélectionné
        if (!totalQuestions && data.totalQuestions) {
          setTotalQuestions(data.totalQuestions); // Mettre à jour le nombre total de questions si disponible via API
        }
      } else {
        setIsQuizFinished(true); // Terminer le quiz si aucune question n'est retournée
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
    }
  }, [type, textQuiz, user, totalQuestions]);

  // Charger la première question lors de l'initialisation
  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion]);

  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async (choice) => {
    if (!question) return; // Assurer qu'il y a une question en cours

    // Stocker la réponse de l'utilisateur
    setUserResponses((prev) => [...prev, choice]);
    setSelectedChoice(choice);

    try {
      const data = await CallAPI(type, choice, textQuiz, user, 'quiz');
      if (!data.isQuestion) {
        // Stocker la correction
        setCorrections((prevCorrections) => [...prevCorrections, data.content]);

        // Passer à la question suivante ou évaluer les réponses
        if (currentQuestionIndex + 1 < totalQuestions) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          fetchQuestion(); // Charger la question suivante
        } else {
          await evaluateResponses(); // Fin du quiz, évaluer les réponses
        }
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Évaluer les réponses de l'utilisateur
  const evaluateResponses = async () => {
    try {
      // Préparer les données à envoyer à l'API pour évaluation
      const evaluationData = {
        responses: userResponses,
        corrections, // Les corrections déjà collectées
      };

      // Appeler l'API pour obtenir le score et les remarques
      const result = await CallAPI(type, evaluationData, textQuiz, user, 'evaluer');
      setFinalResults(result); // Stocker les résultats
      setIsQuizFinished(true); // Finir le quiz
    } catch (error) {
      console.error('Erreur lors de l\'évaluation des réponses:', error);
    }
  };

  // Fonction pour passer à la correction suivante
  const handleNextCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.min(prevIndex + 1, corrections.length - 1));
  };

  // Fonction pour revenir à la correction précédente
  const handlePreviousCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className='quiz-initDiv'>
      {isQuizFinished ? (
        <div className="quiz-results">
          <h2>Quiz terminé !</h2>
          {finalResults && (
            <div className="final-results">
             <p>Score: Vous avez obtenu {finalResults.score}\10. {finalResults.content}</p>
            </div>
          )}
          <div className="quiz-responses">
            {corrections.length > 0 && (
              <div className="quiz-response">
                <p>Correction pour la question {currentCorrectionIndex + 1}: {corrections[currentCorrectionIndex]}</p>
              </div>
            )}
          </div>
          <div className="correction-navigation">
            <button className="buttonCorrection" onClick={handlePreviousCorrection} disabled={currentCorrectionIndex === 0}>
              Précédent
            </button>
            <button className="buttonCorrection" onClick={handleNextCorrection} disabled={currentCorrectionIndex === corrections.length - 1}>
              Suivant
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz-questionD">
          <div className="quiz-questionP">
            {isLoading ? (
              <div className="loader">Chargement...</div> // Affichage du loader pendant le chargement
            ) : (
              <>
                <p>Question {currentQuestionIndex + 1} sur {totalQuestions}</p>
                <p>{question}</p>
                <div className="quiz-responseQCM">
                  <button
                    className={`buttonQCM ${selectedChoice === 'Vrai' ? 'selected' : ''}`}
                    onClick={() => handleResponseSubmit('Vrai')}
                  >
                    Vrai
                  </button>
                  <button
                    className={`buttonQCM ${selectedChoice === 'Faux' ? 'selected' : ''}`}
                    onClick={() => handleResponseSubmit('Faux')}
                  >
                    Faux
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
