import './tools.css';
import corriger from "../../../assets/img/IconTools/corriger.svg";
import definition from "../../../assets/img/IconTools/definition.svg";
import dictionnaire from "../../../assets/img/IconTools/dictionnaire.svg";
import explication from "../../../assets/img/IconTools/explication.svg";
import lecture from "../../../assets/img/IconTools/lecture.svg";
import resume from "../../../assets/img/IconTools/resume.svg";
import traduire from "../../../assets/img/IconTools/traduire.svg";

export const Tool = ({ handleActionClick, style }) => {
    return (
        <div className="tools">
            <div className="tool">
                <img className="action" title="expliquer" alt="explication" src={explication} onClick={() => handleActionClick('explain')} />
                <span className="tooltip">Expliquer</span>
            </div>
            <div className="tool">
                <img className="action" title="reformuler" alt="reformuler" src={dictionnaire} onClick={() => handleActionClick('reformuler')} />
                <span className="tooltip">Reformuler</span>
            </div>
            <div className="tool">
                <img className="action" title="dictionnaire" alt="dictionnaire" src={definition} onClick={() => handleActionClick('dictionnaire')} />
                <span className="tooltip">Dictionnaire</span>
            </div>
            <div className="tool">
                <img className="action" title="résumer" alt="resume" src={resume} onClick={() => handleActionClick('resume')} />
                <span className="tooltip">Résumer</span>
            </div>
            <div className="tool">
                <img className="action" title="corriger" alt="corriger" src={corriger} onClick={() => handleActionClick('correct')} />
                <span className="tooltip">Corriger</span>
            </div>
            <div className="tool">
                <img className="action" title="lire" alt="lecture" src={lecture} onClick={() => handleActionClick('lire')} />
                <span className="tooltip">Lire</span>
            </div>
            <div className="tool">
                <img className="action" title="traduire" alt="traduire" src={traduire} onClick={() => handleActionClick('translate')} />
                <span className="tooltip">Traduire</span>
            </div>
        </div>
    );
};
