import axios from "axios";
import { API_URL } from "../globals";

const login = async (email, password) => {
    try {
        const response = await axios.post(API_URL + "users/login", { email, password });

        return response; // You can return the user details or any other data you want
    } catch (error) {
        // Handle errors here
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Login error: ", errorCode, errorMessage);
        throw error; // Re-throw error to handle it in the caller
    }
};


export { login }