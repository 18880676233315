import React from "react";
import "./MainContainer.css"


const MainContainer = (props) => {
    return <div className="main-container-main-div" style={{ margin: props.margin }}  >
        <div className="main-container-secondary-div" style={props.style}>
            {props.children}
        </div>
    </div>
}

export { MainContainer }