import React from "react";

const BackButtonSvg = ({ color = "currentColor" }) => (
    <button style={{ background: "none", border: "none", padding: 0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24">
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
    </button>
);

export { BackButtonSvg };
