import React from "react";
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { removeDocument } from "../../reducers/src/documentSlice";
import { PrimaryToolBar } from "../../components";
import BandePerforee from "./bandePerforee";
import logo from "./home-logo.png";
import guide from "./guide.png"
import './home.css'
import { HomeShapeSvg } from "../../assets/svg/src/homeShape";
const Home = () => {
    const document = useSelector((state) => state.document)
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    if (document.status !== 'idle') {
        dispatch(removeDocument())
    }
    

    return (
        <div className="home-div">
            <PrimaryToolBar />
            <div className="contenu-home">
                <div className="eugenia-pres">
                    <div className="eugenia-pres1">
                        <h1>C'est quoi Eugenia ?</h1>
                        <p>Eugenia est un outil pédagogique boosté par IA, qui s'adapte à ton profil d'apprentissage et optimise tes révisions. </p>
                        <p>Commence dès maintenant en important un document ou en affinant ton profil!</p>
                        <div className="button-cont">
                            <button className="button-home">Personnalisez votre expérience</button>
                        </div>
                    </div>
                    <div className="img-cont">
                        <img src={logo} alt={"logo-home"} className="logo-home"></img>
                    </div>
                </div>

                <div className="bande-perforee">
                <h1>Comment ça marche?</h1>
                <div className="butt-second">
                                <button className="button1" ><span>Vidéos</span></button>
                                <button className="button1" ><span>FAQ</span></button>
                            </div>
                </div>

                <div className="eugenia-pres1">
                    <h1>En savoir plus</h1>
                    <div className="eugenia-pres2">
                        <div className="img-cont">
                            <img src={guide} alt={"image-guide"} className="guide-img" ></img>
                        </div>
                        <div className="text-par">
                            <p>Aidez nous à en savoir plus sur vous en répondant à un simple questionnaire à propos de vos habitudes et préférences d’apprentissage.</p>
                            <p>Eugenia s’occupe de vous proposer les outils adaptés et personnalisera votre expérience de révision !</p>
                        </div>
                    </div>
                </div>
                <div className="bande-perforee2">
                    <h1>Des questions?</h1>
                    <p>Découvrez en plus sur le fonctionnement de l’outil grâce ànotre guide disponible en ligne, ou en découvrant les questions fréquemment posées par nos utilisateurs</p>
                    <div className="button-cont">
                        <div className="button-home">Découvrir le guide</div>
                    </div>
                </div>
                <div className="contact-div">
                    <div className="text-contact">
                        <h1>Contact</h1>
                        <p>Email</p>
                        <p>telephone</p>
                        <p>Adresse postale</p>
                        <p>Instagram</p>
                    </div>
                    <div className="text-contact">
                        <h1>Partenaires</h1>
                        <p>Part 1</p>
                        <p>Part 2</p>
                    </div>
                    <div>
                        <h1>Mentions légales</h1>
                        <p>CopyRight</p>
                        <p>A propos de nous</p>
                        <p>Confidentialité</p>
                    </div>

                </div>

            </div>
        </div>

    )
}

export { Home }