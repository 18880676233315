import React from "react"

export const DocumentSvg = ({ fillColor, className }) => {

    return (<svg className={className} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_214_366)">
            <path d="M16.5 22.35V25H19.15L25.6125 18.5375L22.9625 15.8875L16.5 22.35Z" fill={fillColor ?? "white"} />
            <path d="M24 7.5L16.5 0H6.5C5.125 0 4.0125 1.125 4.0125 2.5L4 22.5C4 23.875 5.1125 25 6.4875 25H14V21.3125L24 11.3125V7.5ZM15.25 8.75V1.875L22.125 8.75H15.25Z" fill={fillColor ?? "white"} />
            <path d="M27.3875 15L26.5 14.1125C26.0125 13.625 25.225 13.625 24.7375 14.1125L23.85 15L26.5 17.65L27.3875 16.7625C27.875 16.275 27.875 15.4875 27.3875 15Z" fill={fillColor ?? "white"} />
        </g>
        <defs>
            <filter id="filter0_d_214_366" x="0" y="0" width="31.7531" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_366" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_366" result="shape" />
            </filter>
        </defs>
    </svg>)
}
