import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI'; 
import CorrectionComponent from './CorrectionComponent'; 
import { useSelector } from 'react-redux';

export const QuestionOuverte = ({ type }) => {
  const user = useSelector((state) => state.user);
  const documentState = useSelector((state) => state.document);
  const textQuiz = documentState.value;

  const [question, setQuestion] = useState('');
  const [userResponse, setUserResponse] = useState('');
  const [corrections, setCorrections] = useState([]); // Pour stocker les corrections
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalResults, setFinalResults] = useState(null); // Pour stocker les résultats finaux
  const [currentCorrectionIndex, setCurrentCorrectionIndex] = useState(0); // Index de correction actuelle
  const [totalQuestions, setTotalQuestions] = useState(10); // Dynamique, mis à jour via API
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Pour suivre la question actuelle

  // Fonction pour générer la question
  const fetchQuestion = useCallback(async () => {
    setIsLoading(true); // Activer l'état de chargement
    try {
      const data = await CallAPI(type, null, textQuiz, user,'quiz'); 
      if (data.isQuestion) {
        setQuestion(data.content); 
        setUserResponse(''); // Réinitialiser la réponse de l'utilisateur
        setCorrections([]); // Réinitialiser les corrections
        if (data.totalQuestions) {
          setTotalQuestions(data.totalQuestions); // Mettre à jour le nombre total de questions si disponible via API
        }
      } else {
        setIsQuizFinished(true); // Terminer le quiz si aucune question n'est retournée
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
    }
  }, [type, textQuiz, user]);

  useEffect(() => {
    fetchQuestion(); // Générer la première question au montage du composant
  }, [fetchQuestion]);

  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async () => {
    if (!userResponse.trim()) return; // Assurer que la réponse n'est pas vide

    try {
      const data = await CallAPI(type, userResponse, textQuiz, user,'quiz'); 
      if (!data.isQuestion) {
        // Stocker la correction
        setCorrections((prevCorrections) => [...prevCorrections, data.content]);
        // Passer à la question suivante ou évaluer les réponses
        if (currentQuestionIndex + 1 < totalQuestions) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          fetchQuestion(); // Charger la question suivante
        } else {
          await evaluateResponses(); // Fin du quiz, évaluer les réponses
        }
      } else {
        setQuestion(data.content); // Charger la nouvelle question si la réponse est correcte
        setUserResponse(''); 
        setCorrections([]); 
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Évaluer les réponses de l'utilisateur
  const evaluateResponses = async () => {
    try {
      const evaluationData = {
        responses: corrections, // Les corrections déjà collectées
      };
      const result = await CallAPI(type, evaluationData, textQuiz, user, 'evaluer');
      setFinalResults(result); // Stocker les résultats
      setIsQuizFinished(true); // Finir le quiz
    } catch (error) {
      console.error('Erreur lors de l\'évaluation des réponses:', error);
    }
  };

  // Fonction pour passer à la correction suivante
  const handleNextCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.min(prevIndex + 1, corrections.length - 1));
  };

  // Fonction pour revenir à la correction précédente
  const handlePreviousCorrection = () => {
    setCurrentCorrectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className='quiz-initDiv'>
      {isQuizFinished ? (
        <div className="quiz-results">
          <h2>Quiz terminé !</h2>
          {finalResults && (
            <div className="final-results">
              <p>Score: Vous avez obtenu {finalResults.score}\10. {finalResults.content}</p>
            </div>
          )}
          <div className="quiz-responses">
            {corrections.length > 0 && (
              <div className="quiz-response">
                <p>Correction pour la question {currentCorrectionIndex + 1}: {corrections[currentCorrectionIndex]}</p>
              </div>
            )}
          </div>
          <div className="correction-navigation">
            <button className="buttonCorrection" onClick={handlePreviousCorrection} disabled={currentCorrectionIndex === 0}>
              Précédent
            </button>
            <button className="buttonCorrection" onClick={handleNextCorrection} disabled={currentCorrectionIndex === corrections.length - 1}>
              Suivant
            </button>
          </div>
        </div>
      ) : (
        <>
            <div className="quiz-questionD">
                <div className="quiz-questionP">
                    {isLoading ? (
                    <div className="loader">Chargement...</div> // Affichage du loader pendant le chargement
                     ) : (
              <>
                <p>Question {currentQuestionIndex + 1} sur {totalQuestions}</p>
                <p>{question}</p>
                
              </>
            )}
               </div>
               </div>
          <div className="quiz-responseA">
                  <input
                    className="quiz-response"
                    placeholder="Écrire une réponse..."
                    value={userResponse}
                    onChange={(e) => setUserResponse(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleResponseSubmit(); 
                      }
                    }}
                  />
                </div>
               </>
      )}
    </div>
  );
};
