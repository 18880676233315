import React from "react";
import "./PrimaryToolBar.css"


export const SecondaryToolBar = () => {

    return <div style={{ backgroundColor: "#D9D9D9", gap: "5px", display: "flex", flexDirection: "column" }}   >
        <div style={{ height: "20px" }} />
        <SecondaryBarElement text="INFORMATION" selected={true} />
        <SecondaryBarElement text="NOTIFICATIONS " selected={false} />
        <SecondaryBarElement text="ACCES" selected={false} />
        <SecondaryBarElement text="STATISTIQUES " selected={false} />
        <SecondaryBarElement text="ASSISTANCE " selected={false} />
        <SecondaryBarElement text="ABONNEMENTS " selected={false} />
        <SecondaryBarElement text="PARTAGE " selected={false} />
        <SecondaryBarElement text="PARAMETRES " selected={false} />
        <SecondaryBarElement text="PERSONNALISATION " selected={false} />
        <SecondaryBarElement text="CONFIDENTIELITE " selected={false} />
    </div >
}


const SecondaryBarElement = (props) => {
    return <div style={{
        background: props.selected ? "linear-gradient(to right, #AB0B44, #FF4D4D)" : null,
        color: props.selected ? "white" : "#8D8C8C",
        padding: "10px",
        fontWeight: "700",
        fontSize: "20px",
        cursor: props.selected ? "pointer" : null
    }} > {props.text} </div>
}

