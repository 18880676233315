/**
 * Ma documentation: ce composant a pour role d'affichier une question à choix multiples, et sa correction si la reponse est fausse
 */




import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI'; 
import CorrectionComponent from './CorrectionComponent'; 
import { useSelector } from 'react-redux';
export const QuizSim = ({ type, textQuiz }) => {
    const user = useSelector((state) => state.user);
  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState('');
  const [correction, setCorrection] = useState('');
  const [isNextQuestionAvailable, setIsNextQuestionAvailable] = useState(false);
  const [showCorrection, setShowCorrection] = useState(false); 
  const [tablequestions, setTableQ] = useState([]);

  // Fonction pour générer la première question
  const fetchQuestion = useCallback(async () => {
    
    try {
        const Data = {
            userResponses: null,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (data.isQuestion) {
        const { question, options } = parseQCM(data.content); // Extraction des choix et de la question
        setTableQ((prevCorrections) => [...prevCorrections, data.content]);
        setQuestion(question);
        setChoices(options);
        setCorrection('');
        setIsNextQuestionAvailable(false);
        setShowCorrection(false);
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    }
  }, [type, textQuiz,user]);

  useEffect(() => {
    fetchQuestion(); // Générer la première question au montage du composant
  }, [fetchQuestion]);

  // Fonction pour analyser les questions et les choix à partir du texte
  const parseQCM = (text) => {
    const lines = text.split('\n').filter((line) => line.trim() !== ''); // Filtrer les lignes vides
    const options = lines.slice(-4).map((line) => { // On prend seulement les 4 dernières lignes
        const optionParts = line.split(') '); // Séparer par ') ' pour enlever les lettres (A), B), etc.)
        return optionParts.length > 1 ? optionParts[1].trim() : optionParts[0].trim(); // Retourne l'option sans lettre
    });
    const question = lines.slice(0, -4).join('\n').trim(); // Joindre toutes les lignes sauf les dernières 4 pour la question
    return { question, options };
};




  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async (choice) => {
    setSelectedChoice(choice); 
    try {
        const Data = {
            userResponses: choice,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (!data.isQuestion) {
        setCorrection(data.content); 
        setIsNextQuestionAvailable(true); 
        setShowCorrection(true); 
      } else {
        const { question, options } = parseQCM(data.content);
        setQuestion(question);
        setChoices(options);
        setCorrection('');
        setSelectedChoice('');
        setIsNextQuestionAvailable(false);
        setShowCorrection(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Fonction pour passer à la question suivante
  const handleNextQuestion = () => {
    setSelectedChoice(''); 
    fetchQuestion(); 
  };

  return (
    <div className='quiz-initDiv'>
      {showCorrection ? (
        <CorrectionComponent
          correction={correction}
          onNext={handleNextQuestion}
        />
      ) : (
        <>
          <div className="quiz-questionD">
            <div className="quiz-questionP">
              {question} 
              <div className="quiz-responseQCM">
                {choices.map((choice, index) => (
                  <button
                    key={index}
                    className={`buttonQCM ${selectedChoice === choice ? 'selected' : ''}`}
                    onClick={() => handleResponseSubmit(choice)}
                  >
                    {choice}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};