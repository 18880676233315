import { API_URL } from "../../../API/src/globals";

export async function InteractWithAPI(action, phrase, langue, langueTr, age, passions, contentPreference, examplePreference) {
  //require('dotenv').config();
  const url = API_URL + `api/chatgpt/${action}`;
  console.log("dans interactWithAPI", url);
  const body = langueTr
    ? JSON.stringify({ phrase, langue, langueTr, age, passions, contentPreference, examplePreference })
    : JSON.stringify({ phrase, langue, age, passions, contentPreference, examplePreference });

  try {
    //requete
    console.log("Dans interactWithAPI l'action est", action);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Extraire les données de la réponse
    const data = await response.json();

    return data[action];
  } catch (error) {
    console.error(`Error in ${action} request:`, error);
    throw error;
  }
}
