import './questionD.css';
import React from 'react';

export const CorrectionComponent = ({ correction, onNext }) => {
  return (
    
    <div className="correction-container">
      <div className="quiz-questionD">
            <div className="quiz-questionP">
              {correction}
            </div>
        </div>
        <button className='buttonCorrection' onClick={onNext}>Question suivante</button>
    </div>
  );
};

export default CorrectionComponent;
