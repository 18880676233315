import './Documents.css';
import { AppHeader, CercleDocument, FileImport, TextEditor } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { registerFiche } from '../../reducers/src/ficheSlice';
import { InteractWithAPI } from '../../components/src/ReponseIA/InteractWithAPI';
import { useState } from 'react';
import { registerDocument } from '../../reducers/src/documentSlice';
import { registerEditorValue } from '../../reducers/src/editorSlice';

function Documents() {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.user)
  const editorState = useSelector((state) => state.editor)
  const ficheState = useSelector((state) => state.fiche)


  const documentState = useSelector((state) => state.document)
  const [headerState, setHeaderState] = useState(0)

  const dispatch = useDispatch()


  const handleFiche = async () => {
    setLoading(true)
    try {
      console.log("la valeur de documentState.value récupere est dans handleFiche", documentState.value);

      const data = await InteractWithAPI('fiche', documentState.value, user.langue, user.age, user.passions, user.contentPreference, user.examplePreference);
      dispatch(registerFiche({ fiche: data }));
      dispatch(registerEditorValue(data))
      setHeaderState(1)

      setLoading(false)
      console.log("la valeur de text récupere est dans handleFiche", data);
    } catch (error) {

      setLoading(false)
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  const handleHeaderState = (selected) => {
    setHeaderState(selected)
    if (selected === 0 && documentState.value !== editorState.value) {
      dispatch(registerFiche({ fiche: editorState.value }))
    } else if (selected === 1 && ficheState.value !== editorState.value) {
      if (documentState.type !== "pdf") {
        dispatch(registerDocument({ document: editorState.value }))
      }
    }
    dispatch(registerEditorValue(selected === 0 ? documentState.value : ficheState.value))

  }


  return (

    <div className="documents-main-container">
      <AppHeader showSearch={false} />
      <FileImport handleHeaderState={handleHeaderState} headerState={headerState} />
      <div style={sonDiv} >
        <CercleDocument handleFiche={handleFiche} loading={loading} />
      </div>
    </div>
  );
}

export { Documents };

const sonDiv = {
  position: "absolute",
  backgroundColor: "transparent",
  bottom: 0,
  left: 0,
  padding: "10px",
  height: "55px"
}