/**
 * Ma documentation: ce composant a pour role d'affichier une question Fermee, et sa correction si la reponse est fausse
 */


import React, { useState, useEffect, useCallback } from 'react';
import './questionD.css';
import { CallAPI } from './CallAPI'; 
import CorrectionComponent from './CorrectionComponent'; 
import { useSelector } from 'react-redux';
export const FermeeSim = ({ type, textQuiz }) => {
    const user = useSelector((state) => state.user);
  const [question, setQuestion] = useState('');
  const [selectedChoice, setSelectedChoice] = useState('');
  const [correction, setCorrection] = useState('');
  const [isNextQuestionAvailable, setIsNextQuestionAvailable] = useState(false);
  const [showCorrection, setShowCorrection] = useState(false); 
  const [tablequestions, setTableQ] = useState([]);

  // Fonction pour générer la première question
  const fetchQuestion = useCallback(async () => {
    try {
        const Data = {
            userResponses: null,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (data.isQuestion) {
        setTableQ((prevCorrections) => [...prevCorrections, data.content]);
        setQuestion(data.content); 
        setCorrection('');
        setIsNextQuestionAvailable(false);
        setShowCorrection(false);
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la question:', error);
    }
  }, [type, textQuiz]);

  useEffect(() => {
    fetchQuestion(); 
  }, [fetchQuestion]);

  // Fonction pour gérer la soumission de la réponse
  const handleResponseSubmit = async (choice) => {
    setSelectedChoice(choice); 
    try {
        const Data = {
            userResponses: choice,
            tablequestions,
          };
        const data = await CallAPI(type, Data, textQuiz, user, 'similar');
      if (!data.isQuestion) {
        setCorrection(data.content); 
        setIsNextQuestionAvailable(true); 
        setShowCorrection(true); 
      } else {
        setQuestion(data.content);
        setCorrection('');
        setSelectedChoice('');
        setIsNextQuestionAvailable(false);
        setShowCorrection(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  // Fonction pour passer à la question suivante
  const handleNextQuestion = () => {
    setSelectedChoice(''); 
    fetchQuestion(); 
  };

  return (
    <div className='quiz-initDiv'>
      {showCorrection ? (
        <CorrectionComponent
          correction={correction}
          onNext={handleNextQuestion}
        />
      ) : (
        <>
          <div className="quiz-questionD">
            <div className="quiz-questionP">
              {question}
              <div className="quiz-responseQCM">
                <button
                  className={`buttonQCM ${selectedChoice === 'Vrai' ? 'selected' : ''}`}
                  onClick={() => handleResponseSubmit('Vrai')}
                >
                  Vrai
                </button>
                <button
                  className={`buttonQCM ${selectedChoice === 'Faux' ? 'selected' : ''}`}
                  onClick={() => handleResponseSubmit('Faux')}
                >
                  Faux
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};