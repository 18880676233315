import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    border: none;
    outline: none;
    font-size: 20px;
    padding: 7px;
    border-radius: 20px;
    background-color: transparent;
    width: 100%;
    &::placeholder {
        color: ${props => props.style.hintColor};
        opacity: 1;
}
`;

const PrimaryInput = ({
    icon,
    width = '350px',
    style,
    hint,
    onChange,
    name,
    value,
    type
}) => {
    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        border: '2px solid #ffffff',
        width: width,
    };



    return (
        <div style={searchContainerStyle}>
            <Input type={type ?? "text"} onChange={onChange} name={name} value={value} placeholder={hint} style={style} />
            {icon === undefined ? <div />
                : <button style={searchButtonStyle}>
                    <img src={icon} alt="icon" style={iconStyle} />
                </button>}
        </div>
    );
};


const searchButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconStyle = {
    borderRadius: '50%',
    width: "25px",
    height: "25px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
};



export { PrimaryInput };
