import React from "react";


const Donwload = ({ fillColor }) => {
    return (<svg width="140" height="144" viewBox="0 0 140 144" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70 103.982C68.8333 103.982 67.7396 103.793 66.7188 103.416C65.6979 103.045 64.75 102.41 63.875 101.512L32.375 69.1717C30.7708 67.5247 29.9688 65.4286 29.9688 62.8833C29.9688 60.3381 30.7708 58.2419 32.375 56.595C33.9792 54.9481 36.0558 54.0857 38.605 54.0078C41.16 53.9359 43.2396 54.7235 44.8438 56.3704L61.25 73.2142V8.98333C61.25 6.43806 62.09 4.30302 63.77 2.57822C65.4442 0.859407 67.5208 0 70 0C72.4792 0 74.5588 0.859407 76.2388 2.57822C77.9129 4.30302 78.75 6.43806 78.75 8.98333V73.2142L95.1562 56.3704C96.7604 54.7235 98.84 53.9359 101.395 54.0078C103.944 54.0857 106.021 54.9481 107.625 56.595C109.229 58.2419 110.031 60.3381 110.031 62.8833C110.031 65.4286 109.229 67.5247 107.625 69.1717L76.125 101.512C75.25 102.41 74.3021 103.045 73.2812 103.416C72.2604 103.793 71.1667 103.982 70 103.982ZM17.5 143.733C12.6875 143.733 8.56917 141.976 5.145 138.46C1.715 134.939 0 130.708 0 125.767V107.8C0 105.255 0.837085 103.12 2.51125 101.395C4.19125 99.6761 6.27083 98.8167 8.75 98.8167C11.2292 98.8167 13.3087 99.6761 14.9887 101.395C16.6629 103.12 17.5 105.255 17.5 107.8V125.767H122.5V107.8C122.5 105.255 123.34 103.12 125.02 101.395C126.694 99.6761 128.771 98.8167 131.25 98.8167C133.729 98.8167 135.806 99.6761 137.48 101.395C139.16 103.12 140 105.255 140 107.8V125.767C140 130.708 138.288 134.939 134.864 138.46C131.434 141.976 127.312 143.733 122.5 143.733H17.5Z" fill={fillColor} />
    </svg>)
}

export { Donwload }
