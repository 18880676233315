import React from "react"

export const CartableSvg = ({ fillColor, className }) => {

    return (<svg className={className} width="32" height="33" viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_214_454)">
            <path d="M7 30C6.175 30 5.46875 29.7062 4.88125 29.1187C4.29375 28.5312 4 27.825 4 27V15C4 12.875 4.55625 10.975 5.66875 9.3C6.78125 7.625 8.225 6.375 10 5.55V4.5C10 3.25 10.4375 2.1875 11.3125 1.3125C12.1875 0.4375 13.25 0 14.5 0C15.75 0 16.8125 0.4375 17.6875 1.3125C18.5625 2.1875 19 3.25 19 4.5V5.55C20.775 6.375 22.2188 7.625 23.3313 9.3C24.4438 10.975 25 12.875 25 15V27C25 27.825 24.7063 28.5312 24.1188 29.1187C23.5313 29.7062 22.825 30 22 30H7ZM19 21C19.425 21 19.7812 20.8562 20.0688 20.5687C20.3563 20.2812 20.5 19.925 20.5 19.5V18C20.5 17.175 20.2063 16.4688 19.6188 15.8812C19.0313 15.2937 18.325 15 17.5 15H10C9.575 15 9.21875 15.1437 8.93125 15.4312C8.64375 15.7188 8.5 16.075 8.5 16.5C8.5 16.925 8.64375 17.2812 8.93125 17.5687C9.21875 17.8562 9.575 18 10 18H17.5V19.5C17.5 19.925 17.6438 20.2812 17.9313 20.5687C18.2188 20.8562 18.575 21 19 21ZM13 4.65C13.275 4.6 13.525 4.5625 13.75 4.5375C13.975 4.5125 14.225 4.5 14.5 4.5C14.775 4.5 15.025 4.5125 15.25 4.5375C15.475 4.5625 15.725 4.6 16 4.65V4.5C16 4.075 15.8563 3.71875 15.5688 3.43125C15.2812 3.14375 14.925 3 14.5 3C14.075 3 13.7188 3.14375 13.4313 3.43125C13.1438 3.71875 13 4.075 13 4.5V4.65Z" fill={fillColor ?? "white"} />
        </g>
        <defs>
            <filter id="filter0_d_214_454" x="0" y="0" width="29" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_454" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_454" result="shape" />
            </filter>
        </defs>
    </svg>
    )
}