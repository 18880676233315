import React from "react"

export const SaveSvg = ({ fillColor }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.9687 25.5C2.20481 25.5 1.55064 25.2282 1.0062 24.6847C0.462677 24.1403 0.190918 23.4861 0.190918 22.7222V3.27778C0.190918 2.51389 0.462677 1.85972 1.0062 1.31528C1.55064 0.771759 2.20481 0.5 2.9687 0.5H18.4895C18.8599 0.5 19.2131 0.569445 19.5492 0.708333C19.8844 0.847222 20.1793 1.04398 20.434 1.29861L24.3923 5.25694C24.6469 5.51157 24.8437 5.80648 24.9826 6.14167C25.1215 6.47778 25.1909 6.83102 25.1909 7.20139V22.7222C25.1909 23.4861 24.9192 24.1403 24.3756 24.6847C23.8312 25.2282 23.177 25.5 22.4131 25.5H2.9687ZM12.6909 21.3333C13.8483 21.3333 14.8321 20.9282 15.6423 20.1181C16.4525 19.3079 16.8576 18.3241 16.8576 17.1667C16.8576 16.0093 16.4525 15.0255 15.6423 14.2153C14.8321 13.4051 13.8483 13 12.6909 13C11.5335 13 10.5497 13.4051 9.73953 14.2153C8.92934 15.0255 8.52425 16.0093 8.52425 17.1667C8.52425 18.3241 8.92934 19.3079 9.73953 20.1181C10.5497 20.9282 11.5335 21.3333 12.6909 21.3333ZM5.74647 10.2222H15.4687C15.8622 10.2222 16.1923 10.0889 16.459 9.82222C16.7247 9.55648 16.8576 9.22685 16.8576 8.83333V6.05556C16.8576 5.66204 16.7247 5.33194 16.459 5.06528C16.1923 4.79954 15.8622 4.66667 15.4687 4.66667H5.74647C5.35295 4.66667 5.02286 4.79954 4.7562 5.06528C4.49046 5.33194 4.35758 5.66204 4.35758 6.05556V8.83333C4.35758 9.22685 4.49046 9.55648 4.7562 9.82222C5.02286 10.0889 5.35295 10.2222 5.74647 10.2222Z" fill={fillColor ?? "black"} />
        </svg>

    )
}
