import React from "react"

export const EquipeSvg = ({ fillColor, className }) => {
    return (<svg className={className} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_214_382)">
            <path d="M16.5 0C9.6 0 4 5.6 4 12.5C4 19.4 9.6 25 16.5 25C23.4 25 29 19.4 29 12.5C29 5.6 23.4 0 16.5 0ZM21.0125 7.925C22.35 7.925 23.425 9 23.425 10.3375C23.425 11.675 22.35 12.75 21.0125 12.75C19.675 12.75 18.6 11.675 18.6 10.3375C18.5875 9 19.675 7.925 21.0125 7.925ZM13.5125 5.95C15.1375 5.95 16.4625 7.275 16.4625 8.9C16.4625 10.525 15.1375 11.85 13.5125 11.85C11.8875 11.85 10.5625 10.525 10.5625 8.9C10.5625 7.2625 11.875 5.95 13.5125 5.95ZM13.5125 17.3625V22.05C10.5125 21.1125 8.1375 18.8 7.0875 15.85C8.4 14.45 11.675 13.7375 13.5125 13.7375C14.175 13.7375 15.0125 13.8375 15.8875 14.0125C13.8375 15.1 13.5125 16.5375 13.5125 17.3625ZM16.5 22.5C16.1625 22.5 15.8375 22.4875 15.5125 22.45V17.3625C15.5125 15.5875 19.1875 14.7 21.0125 14.7C22.35 14.7 24.6625 15.1875 25.8125 16.1375C24.35 19.85 20.7375 22.5 16.5 22.5Z" fill={fillColor ?? "white"} />
        </g>
        <defs>
            <filter id="filter0_d_214_382" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_382" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_382" result="shape" />
            </filter>
        </defs>
    </svg>
    )
}