import { API_URL } from "../../../API/src/globals";

export async function CallAPI(type, userResponse, textQuiz, user, action) {
  const url = API_URL + `api/${action}`;
  //console.log('url:',env.API_URL);
  // Construire le corps de la requête

  const body = JSON.stringify({ type, userResponse, textQuiz, user })
  /*console.log("bosy")
  console.debug(body)*/
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data.isQuestion, data.content)
    return data;
  } catch (error) {
    console.error(`Error in CallAPI quiz request:`, error);
    throw error;
  }
}
