import './navBar.css';
import { CartableSvg, EquipeSvg, DocumentSvg, GuideSvg } from '../../../assets/svg';
import logo from '../../../assets/img/ReponseIA/logo.png';
import { useNavigate } from 'react-router-dom';

export const NavGray = () => {
    return (
        <div className="quizNav">
            <div className='second'>
                <NavElement to={"/documents"} Svg={DocumentSvg} name="Document" active={true} />
                <NavElement Svg={CartableSvg} name="Cartable" active={false} />
                <NavElement Svg={GuideSvg} name="Guide" active={false} />
                <NavElement Svg={EquipeSvg} name="Equipe" active={false} />

            </div>
            <img src={logo} alt="logo" className='logoo' />
        </div>
    )
        ;
}

export default NavGray;

const NavElement = ({ Svg, name, active, to }) => {
    const navigate = useNavigate()

    const Hint = () => {
        if (active) { return null }
        return (
            <span class="tooltiptext">Cette fonctionnalité sera bientôt disponible</span>

        )
    }

    const onClick = () => {
        if (to) {
            navigate(to)
        }
        return
    }

    return (
        <div className='lien' onClick={onClick} style={{ cursor: active ? "pointer" : null }} >
            <Svg fillColor={active ? "#5C5C5C" : "#A4A4A4"} />
            <div className='text' style={{ color: active ? "#5C5C5C" : "#A4A4A4" }} >
                {name}
                <Hint />
            </div>
        </div>
    )
}


