import React from "react";
import "./Selectable.css"


export const Selectable = ({ index, isSelected, onClick, name }) => {
    return (
        <div
            key={index}
            className={`hobby-item ${isSelected ? 'selected' : ''}`}
            onClick={onClick}
        >
            {name}
        </div>
    )
}