import { createSlice } from '@reduxjs/toolkit';

const documentSlice = createSlice({
    name: 'document',
    initialState: {
        value: '',
        pdfFile: null,
        type: "",
        status: "idle",
        visible: true,
    },
    reducers: {
        registerDocument(state, action) {
            state.status = "imported";
            if (action.payload.type) {
                state.type = action.payload.type;
            }
            if (action.payload.pdfFile) {
                state.pdfFile = action.payload.pdfFile
            } if (action.payload.document) {

                state.value = action.payload.document;
            }
        },
        removeDocument(state) {
            state.value = "";
            state.type = "";
            state.status = "idle";
        },
        setDocumentVisible(state, action) {
            state.visible = action.payload
        }
    },
});

export const { registerDocument, removeDocument, setDocumentVisible } = documentSlice.actions;
export default documentSlice.reducer;

