import './quiz.css';
import { AppHeader, NavGray, QuestionOuverte, QuestionQCM, QuestionFermee } from '../../components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//composant des choix de questions
const Contour = ({ choixQuest, onClick }) => {
  return (
    <div className="typeRes" onClick={onClick}>
      <div style={{ width: "10vw", textAlign: 'center', paddingLeft: '30px', paddingRight: '30px' }}>
        {choixQuest}

      </div>
    </div>
  );
};

function Quiz() {
  const textState=useSelector((state)=>state.document);
  const text=textState.value;
  const [choix, setChoix] = useState('');
  const handleChoixClick = (type) => {
    setChoix(type);
    //console.log("la valeur de choix",type);
  };

  const QuesitonComponent = () => {
    switch (choix) {
      case 'QCM':
        return <QuestionQCM type={choix} textQuiz={text}/>;
      case 'true ou false':
        return <QuestionFermee type={choix} textQuiz={text}/>;
      case 'Ouverte':
        return <QuestionOuverte type={choix} textQuiz={text}/>;
      default:
        return (
          <div className='setType-div'>
            <h1>Bienvenue dans l'évaluation ! Ce test a pour objectif de vérifier vos connaissances sur les chapitres que vous avez étudiés. Il vous permettra de savoir où vous en êtes dans votre apprentissage et d'identifier les notions à réviser. Bonne chance ! </h1>
            <h2>Choisir le type des questions de quiz :</h2>
            <Contour choixQuest="QCM" onClick={() => handleChoixClick('QCM')} />
            <Contour choixQuest="Fermée" onClick={() => handleChoixClick('true ou false')} />
            <Contour choixQuest="Ouverte" onClick={() => handleChoixClick('Ouverte')} />
          </div>
        );
    }
  };



  return (

    <div className="Quiz">
      <AppHeader showSearch={false} />
      <div className='quizz'>
        <NavGray className="NavGray" />
        <div className='question'>
          <QuesitonComponent />
        </div>
      </div>

    </div>
  );
}

export { Quiz };
