import './quiz.css';
import { AppHeader, NavGray } from '../../components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ChoixQuiz() {
  
  const navigate = useNavigate();

  const quizNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="Quiz">
      <AppHeader showSearch={false} />
      <div className='quizz'>
        <NavGray className="NavGray" />
        <div className='question'>
          <div className='setType-div'>
            <h1>Choisir le type du teste que tu veux effectuer:</h1>
            <div className="typeRes" onClick={() => quizNavigate('/teste-connaissance')}>
              <div style={{ width: "30vw", textAlign: 'center', padding: '10px 30px' }}>
                Teste tes connaissances 
              </div>
            </div>
            <div className="typeRes" onClick={() => quizNavigate('/exercices-similaires')}>
              <div style={{ width: "30vw", textAlign: 'center', padding: '10px 30px' }}>
                Refais des exercices similaires
              </div>
            </div>
            <div className="typeRes" onClick={() => quizNavigate('/cartes-memoire')}>
              <div style={{ width: "30vw", textAlign: 'center', padding: '10px 30px' }}>
                Révise avec des cartes mémoire 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ChoixQuiz };
