import React from 'react';
import { COLORS } from '../../../colors';
import './PrimaryButton.css'
import { LoadingSpinner } from '../Loading/Loading';



const PrimaryButton = ({
    text,
    icon,
    to,
    background,
    width = '165px',
    backgroundColor = "white",
    textColor = COLORS.secondary,
    borderRadius = '26px',
    fontSize = '20px',
    onClick,
    loading = false, // Add loading prop
}) => {
    const buttonStyle = {
        color: textColor,
        fontFamily: '"Sitka-SmallBold", Helvetica',
        fontSize: fontSize,
        fontWeight: 700,
        letterSpacing: '0',
        textAlign: "center",
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
        width: width,
    };

    const divStyle = {
        cursor: loading ? 'not-allowed' : 'pointer', // Disable interaction when loading
        opacity: loading ? 0.7 : 1, // Dim button when loading
        alignItems: 'center',
        backgroundColor: backgroundColor ?? "white",
        background: background ?? null,
        borderRadius: borderRadius,
        textAlign: 'center',
        display: 'flex',
        gap: '10px',
        padding: '10px',
        width: width,
    };

    const iconStyle = {
        height: "20px",
        position: 'relative',
        width: "20px",
    };

    const Icon = ({ icon }) => {
        if (icon === undefined) {
            return null;
        }
        return <img style={iconStyle} alt="Vector" src={icon} />;
    };

    // Simple loading spinner


    return (
        <div style={divStyle} onClick={loading ? null : onClick} >
            {loading ? <LoadingSpinner /> : <Icon icon={icon} />} {/* Show spinner or icon */}
            {!loading && <div style={buttonStyle}>{text}</div>} {/* Hide text while loading */}
        </div>
    );
};

export { PrimaryButton };
