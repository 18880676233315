import React, { useEffect, useState } from 'react';

// Mapping des langues pour la synthèse vocale
const languageMap = {
  english: 'en-US',
  french: 'fr-FR',
  german: 'de-DE',
  spanish: 'es-ES',
  italian: 'it-IT',
};

const TextToSpeech = ({ text, language }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    if (text) {
      const newUtterance = new SpeechSynthesisUtterance(text);
      newUtterance.lang = languageMap[language] || 'en-US';

      newUtterance.onstart = () => setIsSpeaking(true);
      newUtterance.onend = () => setIsSpeaking(false);

      // Annuler toute lecture en cours avant de commencer une nouvelle
      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(newUtterance);
      
      setUtterance(newUtterance); // Stocker l'utterance pour le contrôle

      // Fonction de nettoyage à retourner dans useEffect
      return () => {
        window.speechSynthesis.cancel();
      };
    }
  }, [text, language]);

  const handlePauseResume = () => {
    if (isSpeaking) {
      window.speechSynthesis.pause();
    } else {
      window.speechSynthesis.resume();
    }
  };

  return (
    <div className="text-to-speech-container">
      {isSpeaking && <div className="animation">🎤</div>}
      <button onClick={handlePauseResume}>
        {isSpeaking ? 'Pause' : 'Reprendre'}
      </button>

    </div>
  );
};

export default TextToSpeech;
