import React from "react"

export const CompteSvg = ({ fillColor, className }) => {

    return (<svg className={className} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#compte)">
            <path d="M16.5 0C9.6 0 4 5.6 4 12.5C4 19.4 9.6 25 16.5 25C23.4 25 29 19.4 29 12.5C29 5.6 23.4 0 16.5 0ZM16.5 5C18.9125 5 20.875 6.9625 20.875 9.375C20.875 11.7875 18.9125 13.75 16.5 13.75C14.0875 13.75 12.125 11.7875 12.125 9.375C12.125 6.9625 14.0875 5 16.5 5ZM16.5 22.5C13.9625 22.5 10.9625 21.475 8.825 18.9C10.9375 17.25 13.6 16.25 16.5 16.25C19.4 16.25 22.0625 17.25 24.175 18.9C22.0375 21.475 19.0375 22.5 16.5 22.5Z" fill={fillColor ?? "white"} />
        </g>
        <defs>
            <filter id="compte" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_361" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_361" result="shape" />
            </filter>
        </defs>
    </svg>)
}
