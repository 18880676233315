import React from "react";

export const DocumentHeader = ({ selected = 0, onClick }) => {
    return (
        <div style={{
            height: "30px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingBottom: "5px",
            gap: "2px"
        }} >
            <HeaderElement text="Document" selected={selected === 0} onClick={() => onClick(0)} />
            <HeaderElement text="Fiche" selected={selected === 1} onClick={() => onClick(1)} />

        </div>
    )
}

const HeaderElement = ({ text, selected, onClick }) => {
    return (
        <div
            style={{
                borderRadius: "5px",
                border: selected ? null : "2px solid #D9D9D9",
                width: "100%",
                cursor: "pointer",
                background: selected ? "linear-gradient(to right, #AB0B44, #FF4D4D)" : "white",
                color: selected ? "white" : "black",
                fontWWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "Sitara-Bold, Helvetica"
            }}
            onClick={onClick}

        >
            {text}
        </div>
    )
}