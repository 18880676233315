import { createSlice } from '@reduxjs/toolkit';

const ficheSlice = createSlice({
    name: 'fiche',
    initialState: {
        value: '',
        status: "idle",
        visible: false,
    },
    reducers: {
        registerFiche(state, action) {
            state.status = "computed";

            if (action.payload.fiche) {
                state.value = action.payload.fiche;
            }
        },
        removeFiche(state) {
            state.value = "";
            state.status = "idle";
        },
        setFicheVisible(state, action) {
            state.visible = action.payload;
        },
    },
});

export const { registerFiche, removeFiche, setFicheVisible } = ficheSlice.actions;
export default ficheSlice.reducer;

