import React from "react"

export const GuideSvg = ({ fillColor, className }) => {

    return (<svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="#FF4D4D" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_214_379)">
            <path d="M6.33333 21C5.69167 21 5.14236 20.7715 4.68542 20.3146C4.22847 19.8576 4 19.3083 4 18.6667V2.33333C4 1.69167 4.22847 1.14236 4.68542 0.685417C5.14236 0.228472 5.69167 0 6.33333 0H22.6667C23.3083 0 23.8576 0.228472 24.3146 0.685417C24.7715 1.14236 25 1.69167 25 2.33333V18.6667C25 19.3083 24.7715 19.8576 24.3146 20.3146C23.8576 20.7715 23.3083 21 22.6667 21H6.33333ZM14.5 10.5L17.4167 8.75L20.3333 10.5V2.33333H14.5V10.5Z" fill={fillColor ?? "white"} />
        </g>
        <defs>
            <filter id="filter0_d_214_379" x="0" y="0" width="29" height="29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_379" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_379" result="shape" />
            </filter>
        </defs>
    </svg>)
}
