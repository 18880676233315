import { createSlice } from '@reduxjs/toolkit';

const editorSlice = createSlice({
    name: 'editor',
    initialState: {
        value: '',
    },
    reducers: {
        registerEditorValue(state, action) {
            state.value = action.payload;
        },
        removeEditorValue(state) {
            state.value = "";
        },
    },
});

export const { registerEditorValue, removeEditorValue } = editorSlice.actions;
export default editorSlice.reducer;

