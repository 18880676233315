import React from "react"
import "./PrimaryToolBar.css"
import { useNavigate } from "react-router-dom"

function ToolBarElement({ text, Logo, style = {}, to, isActive = false, onClick }) {
    const navigate = useNavigate();
    const mainContainerStyle = {
        ...style,
        backgroundColor: !isActive ? null : "#D9D9D9",
        cursor: (to || onClick) ? "pointer" : null
    }

    return (
        <div
            style={mainContainerStyle}
            className="toolbar-element-main-container"
            onClick={() => {
                if (onClick) {
                    onClick();
                } else if (to) navigate(to);
            }}  >
            <div className="toolbar-element-sub-container">
                <Logo className="toolbar-element-logo" fillColor={isActive ? "#BF1A46" : "white"} />
                <div style={{ color: !isActive ? "white" : "#BF1A46" }} className="toolbar-element-text" > {text} </div>
            </div>
        </div>
    )
}




export { ToolBarElement }
