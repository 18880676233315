import { combineReducers } from 'redux';
import userReducer from './src/userSlice'; // Import your slice or reducer
import documentSlice from './src/documentSlice';
import ficheSlice from './src/ficheSlice';
import editorSlice from './src/editorSlice';

const rootReducer = combineReducers({
    user: userReducer,  // Combine multiple reducers
    document: documentSlice,
    fiche: ficheSlice,
    editor: editorSlice,
});

export default rootReducer;
